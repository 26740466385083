import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiAxios } from '../../api/axios';
const Feedback = () => {

    const [formSubmitted, setFormSubmitted] = useState(false)

    const schema = yup.object().shape({
        email: yup.string().email().required("Email is required"),
        rating: yup.string().required("Please select"),
        comment: yup.string().required("Comment is required"),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    console.log("errors", errors)

    const onSubmitHandler = async (data) => {
        console.log({ data });
        try {
            const formData = new FormData();
            formData.append("language_id", 1);
            formData.append("rating", data.rating);
            formData.append("email", data.email);
            formData.append("comments", data.comment);

            console.log("formData", formData);
            const response = await apiAxios.post(
                "complaints/add_feedback",
                formData
            );
            setFormSubmitted(true)
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
        reset();
    };

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scrolling smooth
          });
    },[])
    return (
        <div>
            <div className="inner-pages-header-banner" style={{ backgroundImage: 'url(/images/Feedback-page-bg.jpg)' }}>
                <div className="container">
                    <h1>Give Feedback</h1>
                </div>
            </div>

            <section class="mb-5">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="rath-img">
                                <img src="/images/about-image.png" alt="" />
                            </div>
                        </div>
                        <div class="col-md-7">
                            {
                                formSubmitted && (
                                    <div className="alert alert-success alert-dismissible fade show mt-3" role="alert">
                                        <strong>Feedback Submitted Successfully!</strong>
                                    </div>
                                )
                            }
                            <div class="feedback-form">
                                <h2>Feedback</h2>
                                <p>Are you satisficed with our services?</p>
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <div class="smile-rating">
                                        <ul>
                                            <li><div class="tooltip-custom">Very Bad</div><input {...register("rating")} value={"0"} class="form-check-input" type="radio" id="flexRadioDefault1" /><span><img src="/images/rating-1.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">Poor</div><input {...register("rating")} value={"1"} class="form-check-input" type="radio" id="flexRadioDefault2" /><span><img src="/images/rating-2.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">Medium</div><input {...register("rating")} value={"2"} class="form-check-input" type="radio" id="flexRadioDefault3" /><span><img src="/images/rating-3.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">Good</div><input {...register("rating")} value={"3"} class="form-check-input" type="radio" id="flexRadioDefault4" /><span><img src="/images/rating-4.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">Very Good</div><input {...register("rating")} value={"4"} class="form-check-input" type="radio" id="flexRadioDefault5" /><span><img src="/images/rating-5.svg" alt="" /></span></li>
                                            <li><div class="tooltip-custom">Excellent</div><input {...register("rating")} value={"5"} class="form-check-input" type="radio" id="flexRadioDefault6" /><span><img src="/images/rating-6.svg" alt="" /></span></li>
                                        </ul>
                                        <span className="form-error-msg">{errors?.rating?.message}</span>
                                    </div>
                                    <div class="input-field">
                                        <input type="email" class="form-control" placeholder="Enter Email" {...register("email")} />
                                        <span className="form-error-msg">{errors?.email?.message}</span>
                                    </div>
                                    <div class="input-field">
                                        <label htmlFor="">Do you have any thoughts you’d like to share?</label>
                                        <textarea class="form-control" placeholder="Type Comment here.." {...register("comment")}></textarea>
                                        <span className="form-error-msg">{errors?.comment?.message}</span>
                                    </div>
                                    <div class="btn-grp">
                                        <button type="submit" class="submit-btn">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Feedback