import React from 'react'
import { Link } from 'react-router-dom'
import './style/style.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';

const MuseumExhibits = ({ homeExhibits }) => {
    const navigate = useNavigate();

    const exhibitsOptions = {
        loop: false,
        margin: 60,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            }
        }
    }

    const handleOnClick = (id) => {
        navigate(`/exhibits-details?location_id=${id}`);
    }

    return (
        <div className="Museum-Exhibits " style={{ backgroundImage: 'url(/images/gradient-bg.svg)' }}>
            <div className="section-heading">
                <h2>Museum Exhibits</h2>
            </div>

            <div className='container  with-custom-arrow'>
                <OwlCarousel className='owl-theme' {...exhibitsOptions}>
                    {
                        homeExhibits.map((item) => (
                            <div className="Exhibits-items" key={item.location_id} onClick={() => handleOnClick(item.location_id)}>
                                <div className="image-row"><img src={item.web_icon} alt="" /></div>
                                <div className="Exhibits-item-details">
                                    <h4>{item?.location_name}</h4>
                                    <div className='home-exhibits-cat'>
                                        <h4>{item?.category_name}</h4>
                                    </div>
                                    {/* <h6>Oct 20, 2021 - Oct 25, 2020</h6> */}
                                    <p>{item?.description}</p>
                                    <Link to={`/exhibits-details?location_id=${item.location_id}`} className="custom-globle-button">Read More <span><img alt="MuseumExhibits-2"
                                        src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0MiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODQ4Mjg1IDhMNDAuNjE2MSA4TTQwLjYxNjEgOEwzMS41MjYzIDFNNDAuNjE2MSA4TDMxLjUyNjMgMTUiIHN0cm9rZT0iIzNBM0EzQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=' /></span>
                                    </Link>
                                </div>
                            </div>
                        ))
                    }
                </OwlCarousel>
            </div>


        </div>
    )
}

export default MuseumExhibits