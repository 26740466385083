import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import BlogsPage from './pages/Blogs';
import Navbar from './components/Header/navbar';
import Footer from './components/footer';
import BookingPage from './pages/Booking';
import Exhibits from './pages/Exhibits';
import BlogDetails from './pages/Blogs/blogDetails';
import ContactUs from './pages/contactUs';
import ExhibitsDetails from './pages/Exhibits/exhibits-details';
import Gallery from './pages/gallery';
import Feedback from './pages/feedback';
import ProceedTopay from './pages/proceedToPay';
import TicketDetails from './pages/Booking/ticketDetails';
// import About from './pages/About';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

function App() {

  const queryClient = new QueryClient()




  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/booking" element={<BookingPage />} />
            <Route path="/ticket-details" element={<TicketDetails />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blogs/blog_listing" element={<BlogDetails />} />
            <Route path="/exhibits" element={<Exhibits />} />
            <Route path="/exhibits-details" element={<ExhibitsDetails />} />
            <Route path="/blogDetails" element={<BlogDetails />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/procced-to-pay" element={<ProceedTopay />} />
            <Route path="/*" element={<Home />} />
          </Routes>

          <Footer />
        </BrowserRouter>
      </QueryClientProvider>

    </>
  );
}

export default App;




