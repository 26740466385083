import React, { useState } from 'react';

// interface VideoContainerProps {
//   thumbnailSrc: string;
//   videoSrc: string;
//   videoTitle: string; // Prop for iframe title
// }

const VideoContainer = ({ thumbnailSrc, videoSrc, videoTitle }) => {
  // State to manage visibility of thumbnail and iframe
  console.log("22222222222222 thumbnailSrc", thumbnailSrc)
  console.log("22222222222222 videoSrc", videoSrc)
  console.log("22222222222222 videoTitle", videoTitle)
  const [isThumbnailVisible, setThumbnailVisible] = useState(true);
  const [autoplayVideoSrc, setAutoplayVideoSrc] = useState(videoSrc);

  // Event handler for click
  const handleContainerClick = () => {
    setThumbnailVisible(false);
    // Append autoplay parameter to the video URL
    const autoplaySrc = `${videoSrc}?rel=0&autoplay=1`;
    setAutoplayVideoSrc(autoplaySrc);
  };

  // autoplay=1

  return (
    <div className="video-container" onClick={handleContainerClick} style={{ cursor: 'pointer', position: 'relative', width: '100%', maxWidth: '640px' }}>
      {isThumbnailVisible ? (
        <div className="video-thumbnail" style={{ display: 'block' }}>
          <img src={thumbnailSrc} alt="Video Thumbnail" style={{ width: '100%', height: 'auto' }} />
          <div className='playbtn'>
            <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.7847 14.1067C24.8155 14.7614 24.8155 16.2659 23.7847 16.9206L3.56018 29.7648C2.45057 30.4695 1 29.6724 1 28.3579L1 2.66937C1 1.3549 2.45057 0.557754 3.56018 1.26245L23.7847 14.1067Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      ) : (

        <iframe
          className="video-iframe"
          // src="https://www.youtube.com/embed/4aQA60AmxV4?rel=0&autoplay=1"
          src={autoplayVideoSrc}
          title="Jyotisar Teaser"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          style={{ display: 'block', width: '100%', height: '360px' }}
        >
        </iframe>
      )}
    </div>
  );
};

export default VideoContainer;
