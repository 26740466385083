import axios from 'axios'
import { apiAxios } from '../api/axios';



export const getHomePageData = async (signal) => {
    const res = await apiAxios.get("locations/getLocations", { signal });
    console.log("resresres",res)

    const uniqueBlocksExhibits = res.data.data.reduce((acc, current) => {
        if (!acc.find(item => item.category_name === current.category_name)) {
            acc.push(current);
        }
        return acc;
    }, []);

    const imgCrousel = res.data.data.map(item => ({ "id": item.location_id, "image_url": item.web_icon, "title": item.location_name, }));
    const shows = res.data.data.filter((item) => item.type === "show");
    return { uniqueBlocksExhibits, imgCrousel, shows }
}

export const getHomePageAboutData = async (signal) => {
    const res = await apiAxios.get("pages/details/1", { signal });
    return res.data.data
}

export const getMuseumGallery = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const res = await apiAxios.get("/locations/getLocation_media_details?language_id=1&media_type=2", { signal });
    const data = res.data.data.filter((item) => item.type !== "show");
    return data
}

export const getMuseumExhibits = async (signal) => {
    const result = await apiAxios.get("locations/getLocations", { signal });
    const filterarr = [4, 10, 14, 17, 23, 26, 30, 32, 38];

    const fileterLocation = result.data.data.filter(item => filterarr.includes(item.location_id));

    const categories = [...new Set(result.data.data.map(item => item.category_name))];
    categories.sort(function (a, b) {
        let nameA = a.toLowerCase(), nameB = b.toLowerCase();
        if (nameA < nameB)
            return -1;
        if (nameA > nameB)
            return 1;
        return 0;
    });

    const categorizedData = categories.map((category, index) => ({
        category,
        items: result.data.data.filter(item => item.category_name === category),
        slider: index % 2 === 0
    }));
    


    return { fileterLocation, categorizedData }
}



