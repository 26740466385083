import React from 'react'
import './style/style.css'


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';

function truncateText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength - 3) + '...'; // Subtract 3 for the ellipsis
    } else {
        return text;
    }
}

const Shows = ({ shows }) => {




    const showsOptions = {
        loop: false,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: true,
        smartSpeed: 800,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1000: {
                items: 3
            },
            1300: {
                items: 4
            },
            1500: {
                items: 4
            }
        }
    }

    return (
        <div className="Our-Shows">
            <div className="section-heading">
                <h2>Our Shows</h2>
            </div>


            <div className="container-fluid p-0">
                <div id="OurShows" className="with-custom-arrow bellow">
                    <OwlCarousel className='owl-theme' {...showsOptions}>
                        {
                            shows.map((item, index) => (
                                <div className="item" key={item.location_id}>
                                    <div className="OurShows-items">
                                        <div className="image-row">
                                            <img src={item.web_icon} alt="" />
                                        </div>
                                        <div className="OurShows-item-details">
                                            <h4>{truncateText(item?.location_name, 30)}</h4>
                                            {/* <h6>OCT 20, 2021 - OCT 25, 2020</h6> */}
                                            <p>{truncateText(item.description, 30)}</p>
                                            <Link to="/booking" className="Buy-Tickets">
                                                {"Buy Tickets"} <span>
                                                    <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg></span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </OwlCarousel>
                </div>
            </div>
        </div>
    )
}

export default Shows