import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import "./header.css"
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const navigations = [
        { id: 1, name: "Home", href: "/home" },
        { id: 2, name: "Booking", href: "/booking" },
        { id: 3, name: "Exhibits", href: "/exhibits" },
        { id: 4, name: "Newsletter", href: "/blogs" },
        { id: 4, name: "Gallery", href: "/gallery" },
        { id: 5, name: "Contact Us", href: "/contact-us" },
    ]

    const location = useLocation();
    const headerRef = useRef(null);
    const [toggleMenuIcon, setToggleMenuIcon] = useState(false);

    const [windowDimension, setWindowDimension] = useState({
        winWidth: window.innerWidth,
        winheight: window.innerHeight,
    })

    const detectWindowSize = () => {
        setWindowDimension({
            winWidth: window.innerWidth,
            winheight: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener('resize', detectWindowSize)

        return () => window.removeEventListener('resize', detectWindowSize)
    }, [windowDimension]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                headerRef.current &&
                !headerRef.current.contains(event.target) &&
                toggleMenuIcon
            ) {
                setToggleMenuIcon(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [toggleMenuIcon]);


    useEffect(() => {
        // Header Sticky
        const header = document.querySelector(".main-header");
        const toggleClass = "is-sticky";

        window.addEventListener("scroll", () => {
            const currentScroll = window.scrollY;
            if (currentScroll > 150) {
                header.classList.add(toggleClass);
            } else {
                header.classList.remove(toggleClass);
            }
        });

        // Scroll Next Section
        const scrollToNext = document.getElementById('scrollToNext');
        if (scrollToNext) {
            scrollToNext.addEventListener('click', function (event) {
                event.preventDefault();
                const targetId = this.getAttribute('data-target');
                const targetElement = document.getElementById(targetId);
                if (targetElement) {
                    const offset = targetElement.offsetTop - 75;
                    window.scrollTo({
                        top: offset,
                        behavior: 'smooth'
                    });
                }
            });
        }
        // Cleanup function
        return () => {
            window.removeEventListener("scroll", () => { });
            if (scrollToNext) scrollToNext.removeEventListener('click', () => { });
            // if (toggleMenu) toggleMenu.removeEventListener('click', () => { });
            document.removeEventListener('click', () => { });
            // clearInterval(sliderInterval);
        };
    }, []);

    const handleGoToBooking = () => {
        navigate("/booking")
    }

    const handleNavLinkClick = () => {
        setToggleMenuIcon(false);
    };
    return (
        <>
            <div className="main-header">
                <div className="container">
                    <div className={`header-data row ${toggleMenuIcon ? "active" : ""}`} ref={headerRef}>
                        <div className="brand">
                            <Link to="/"> <img src="/images/mahabharat-logo.svg" alt="" /></Link>
                        </div>
                        <nav>
                            <ul>
                                {
                                    navigations.map((item) => (
                                        <>
                                            {
                                                item.name === "Home" && location.pathname === "/" ? (
                                                    <li key={item.id}>
                                                        <NavLink
                                                            to={item.href}
                                                            className="active"
                                                            onClick={handleNavLinkClick}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                    </li>
                                                ) :
                                                    <li key={item.id}>
                                                        <NavLink
                                                            to={item.href}
                                                            className={({ isActive }) => isActive ? "active" : ""}
                                                            onClick={handleNavLinkClick}
                                                        >
                                                            {item.name}
                                                        </NavLink>
                                                    </li>

                                            }


                                        </>
                                    ))
                                }
                            </ul>
                        </nav>
                        {
                            windowDimension.winWidth <= 767 && (
                                !toggleMenuIcon ?
                                    <button className="toggle-menu" onClick={() => setToggleMenuIcon(!toggleMenuIcon)}>
                                        <svg className="open-icon" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5 1.66667H1.33325M24.6665 9.99999H1.33325M22 18.3333C12.8878 18.3333 10.4455 18.3333 1.33325 18.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                    :

                                    <button className="toggle-menu" onClick={() => setToggleMenuIcon(!toggleMenuIcon)}>
                                        <svg className="close-icon" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.16675 12.8333L7.00008 6.99996M7.00008 6.99996L12.8334 1.16663M7.00008 6.99996L12.8334 12.8333M7.00008 6.99996L1.16675 1.16663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>

                            )
                        }
                        {/* <button className="toggle-menu">
                            <svg className="open" width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 1.66667H1.33325M24.6665 9.99999H1.33325M22 18.3333C12.8878 18.3333 10.4455 18.3333 1.33325 18.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <svg className="close" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16675 12.8333L7.00008 6.99996M7.00008 6.99996L12.8334 1.16663M7.00008 6.99996L12.8334 12.8333M7.00008 6.99996L1.16675 1.16663" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button> */}

                        <button className="Buy-Tickets" onClick={handleGoToBooking}>{"Buy Tickets"}<span><svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 6L16 6M16 6L11 1M16 6L11 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg></span></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar