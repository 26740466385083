export const URLS360 = {
    "BLOCK A":"https://youtu.be/N-74u__cplY",
    "BLOCK B":"https://youtu.be/5KqchAp813A",
    "BLOCK C":"https://youtu.be/iFE0for3hMM",
    "BLOCK D":"https://youtu.be/6peGNseilJc",
    "BLOCK E":"https://youtu.be/zTV2wPNQrSo",
}

export const videoArr = [
    {
        url:"https://youtu.be/N-74u__cplY",
        id:"N-74u__cplY"
    },
    {
        url:"https://youtu.be/5KqchAp813A",
        id:"5KqchAp813A"
    },
    {
        url:"https://youtu.be/iFE0for3hMM",
        id:"iFE0for3hMM"
    },
    {
        url:"https://youtu.be/6peGNseilJc",
        id:"6peGNseilJc"
    },
    {
        url:"https://youtu.be/zTV2wPNQrSo",
        id:"zTV2wPNQrSo"
    },
]