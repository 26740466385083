import React, { useEffect, useMemo, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { URLS360 } from '../../constants/youtubeUrls';
import VideoContainer from '../common/aboutYoutubeVideo';
import { getYouTubeVideoId } from '../common/youtubeThumbnailImage';

const BlockExhibitsSlider = ({ data, count }) => {

    const [videoUrl, setVideoUrl] = useState(null)
    const [videoThumbnail, setVideoUrlThumbnail] = useState(null)
    const navigate = useNavigate();

    
    const museumOptions = useMemo(()=>{
        if(count > 3){
            return {
                loop: true,
                margin: 30,
                nav: true,
                dots: false,
                autoplay: true,
                smartSpeed: 800,
                autoplayTimeout: 3000,
                autoplayHoverPause: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    991: {
                        items: 2.5
                    },
                    1500: {
                        items: 3.5
                    }
                }
            }
        }else{
            return {
                loop: false,
                margin: 30,
                nav: true,
                dots: false,
                autoplay: true,
                smartSpeed: 800,
                autoplayTimeout: 3000,
                autoplayHoverPause: true,
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 2
                    },
                    991: {
                        items: 2.5
                    },
                    1500: {
                        items: 3.5
                    }
                }
            }
        }
    },[]);

    const handleClick = (id) => {
        navigate(`/exhibits-details?location_id=${id}`);
    }
    return (
        <div>
            <section className={`Exhibits-lists block-${data.category.split(" ")[1]}`} style={{ backgroundImage: 'url(/images/Museum-Tour-bg.png)' }}>
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-lg-4 p-0 Exhibits-block-left">
                            <div className="MuseumTour-left">
                                <h2>{data.category}</h2>
                                <p>{data.items[0]?.category_description}</p>
                                {/* {
                                    videoUrl && (
                                        <div className='block-video360'>
                                            <VideoContainer
                                                thumbnailSrc={videoThumbnail}
                                                videoSrc={videoUrl}
                                                videoTitle={data.category}
                                            />
                                        </div>
                                    )
                                } */}

                            </div>
                        </div>
                        <div className="col-lg-6 p-0 Exhibits-block-right">
                            <div id="ExhibitsLists" className={`with-custom-arrow bellow`}>
                                <OwlCarousel className='owl-theme' {...museumOptions}>
                                    {
                                        data.items.map((item, index) => (
                                            <div className={`item`} key={item.location_id} onClick={() => handleClick(item.location_id)}>
                                                <div className="exhibits-items slider">
                                                    <img src={item.web_icon} alt="" />
                                                    {/* <Link to="#" className="dview"><img src="images/360-icon.svg" alt="" /></Link> */}
                                                    <h3 className="dview">{item?.location_name}</h3>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default BlockExhibitsSlider