import React, { useEffect, useState } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { ColorRing } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { apiAxios } from '../../api/axios'
import BlockExhibitsSlider from '../../components/Exhibits/blockExhibitsSlider'
import BlockExhibits from '../../components/Exhibits/blockExhibits'
import RandomLocations from '../../components/Exhibits/randomLocations';


import { useQuery } from '@tanstack/react-query'
import { getMuseumExhibits } from '../../services/homeServices';

const ExhibitsPage = () => {

    const [exhibitsData, setExhibitsData] = useState([]);
    const [exhibitsDataLoad, setExhibitsDataLoad] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadmoreButton, setLoadmoreButton] = useState(true)
    const [randomLocation, setRandomLocation] = useState([])


    // const fetchExhibitsData = async () => {
    //     setLoading(true);
    //     try {
    //         const result = await apiAxios.get("locations/getLocations");
    //         console.log(" fileterLocation result.data.data", result.data.data)
    //         const filterarr = [4, 10, 14, 17, 23, 26, 30, 32, 38];

    //         // const fileterLocation = result.data.data.filter((item) => item.location_id == filterarr.includes())
    //         const fileterLocation = result.data.data.filter(item => filterarr.includes(item.location_id));

    //         setRandomLocation(fileterLocation)

    //         const categories = [...new Set(result.data.data.map(item => item.category_name))];
    //         categories.sort(function (a, b) {
    //             let nameA = a.toLowerCase(), nameB = b.toLowerCase();
    //             if (nameA < nameB)
    //                 return -1;
    //             if (nameA > nameB)
    //                 return 1;
    //             return 0;
    //         });

    //         const categorizedData = categories.map((category, index) => ({
    //             category,
    //             items: result.data.data.filter(item => item.category_name === category),
    //             slider: index % 2 === 0
    //         }));

    //         setExhibitsData(categorizedData.slice(0, 4));
    //         setExhibitsDataLoad(categorizedData.slice(4, categorizedData.length))
    //     } catch (err) {

    //     } finally {
    //         setLoading(false);
    //     }

    // }

    // useEffect(() => {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth' // This makes the scrolling smooth
    //     });
    //     fetchExhibitsData()
    // }, []);


    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["exhibits",4],
        queryFn: ({ signal }) => getMuseumExhibits(signal),
    })


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scrolling smooth
        });
        if (data) {
            setRandomLocation(data.fileterLocation)
            setExhibitsData(data.categorizedData.slice(0, 4));
            setExhibitsDataLoad(data.categorizedData.slice(4, data.categorizedData.length))
        }

    }, [data])



    console.log("exhibitsData", exhibitsData)
    return (
        <>
            <div className="exhibits-all-list">
                <div className="inner-pages-header-banner" style={{ backgroundImage: 'url(/images/Exhibits-page-bg.jpg)' }}>
                    <div className="container">
                        <h1>Exhibits</h1>
                    </div>
                </div>

                {
                    isLoading ?
                        <div className='d-flex justify-content-center align-items-center' style={{ height: "300px" }}>
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        </div>
                        :
                        <>
                            {
                                exhibitsData.length > 1 &&
                                <>
                                    <div className='container dont-miss-heading'>
                                        <h1>Dont Miss out</h1>
                                    </div>

                                    <RandomLocations data={randomLocation}/>
                                    {/* <div className="container top-exibits">
                                        {
                                            exhibitsData[0]?.items.slice(0, 4).map((item) => (
                                                <div className="exhibits-items" onClick={() => handleClick(item.location_id)} key={item.location_id}>
                                                    <img src={item.web_icon} alt={item.location_name} />
                                                    <h3 className="english-landscape-painting">
                                                        {item.location_name}
                                                    </h3>
                                                </div>
                                            ))
                                        }
                                    </div> */}
                                </>
                            }


                            {
                                exhibitsData.length > 0 &&
                                exhibitsData.map((item) => {
                                    if (item.slider) {
                                        return <BlockExhibitsSlider data={item} count={item.items.length}/>
                                    } else {
                                        return <BlockExhibits data={item} count={item.items.length} />
                                    }
                                })
                            }

                            {
                                exhibitsDataLoad.length > 0 &&
                                    loadmoreButton ?
                                    <div className="row" style={{ marginTop: "100px" }}><button class="load-more-list" onClick={() => setLoadmoreButton(!loadmoreButton)}>LOAD MORE</button></div>
                                    :

                                    exhibitsDataLoad.map((item) => {
                                        if (item.slider) {
                                            return <BlockExhibitsSlider data={item} />
                                        } else {
                                            return <BlockExhibits data={item} count={item.items.length} />
                                        }
                                    })

                            }
                        </>
                }

            </div >
            {
                isError && <div style={{textAlign:"center"}}>{error.message}</div>
            }
        </>
    )
}

export default ExhibitsPage