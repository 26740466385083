import React, { useEffect, useState } from 'react'
import "./style.css"
import Modal from 'react-bootstrap/Modal';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"
import { apiAxios } from '../../api/axios';
import { ColorRing } from 'react-loader-spinner';
import { truncateData } from '../../components/common/truncateData';
import { useQuery } from '@tanstack/react-query'
import { getMuseumGallery } from '../../services/homeServices';

const Gallery = () => {

    const PAGE_ITEMS = 3;

    const [galleryCat, setGalleryCat] = useState([]);
    const [galleryData, setGalleryData] = useState([]);
    const [galleryFilterDataData, setGalleryFilterData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");
    const [noOfElement, setNoOfElement] = useState(PAGE_ITEMS);

    const [loading, setLoading] = useState(false);

    const slice = galleryFilterDataData.slice(0, noOfElement);

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["gallery"],
        queryFn: getMuseumGallery,
    })

    useEffect(() => {
        if (data) {
            const galerryData = data.filter((item) => item.type !== "show");
            setGalleryData(galerryData)
            setGalleryFilterData(galerryData);
            const resdata = galerryData;
            resdata.sort(function (a, b) {
                let nameA = a.category_name.toLowerCase(), nameB = b.category_name.toLowerCase();
                if (nameA < nameB) //sort string ascending
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0; //default return value (no sorting)
            });

            const uniqueCategoryNames = [...new Set(resdata.map(item => item.category_name))];
            uniqueCategoryNames.unshift("All");
            console.log("uniqueCategoryNames", uniqueCategoryNames)


            const categoryObjects = uniqueCategoryNames.map((name, index) => {
                if (name === "All") {
                    return {
                        id: index + 1,
                        name: name,
                        active: true
                    }
                }
                return {
                    id: index + 1,
                    name: name,
                    active: false
                }
            });
            setGalleryCat(categoryObjects);

        }
    }, [data]);

    const handleCatButton = (id, blockName) => {
        setNoOfElement(PAGE_ITEMS)
        setGalleryCat(prev => prev.map(item => ({
            ...item,
            active: item.id === id ? true : false // Update active property based on id
        })));

        if (blockName === "All") {
            setGalleryFilterData(galleryData);
        } else {
            const data = galleryData.filter((item) => item?.category_name === blockName);
            setGalleryFilterData(data)
            console.log("data", data)
        }
    }

    const handleSlectedImage = (image) => {
        setModalShow(true);
        setSelectedImage(image);
    }

    const handleLoadmore = () => {
        setNoOfElement(noOfElement + noOfElement)
    }

    console.log("galleryCat", galleryData)
    return (
        <div>

            <div class="inner-pages-header-banner" style={{ backgroundImage: 'url(/images/Gallery-page-bg.jpg)' }}>
                <div class="container">
                    <h1>Gallery</h1>
                </div>
            </div>

            <section class="portfolio-section" id="portfolio">
                <div class="container-fluid">

                    {
                        isLoading ?
                            <div className='d-flex justify-content-center align-items-center' style={{ height: "300px" }}>
                                <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                />
                            </div>
                            :
                            slice.length > 0 ? (
                                <div class="gallery-section mb-4" >
                                    <div class="container">
                                        <div class="portfolio-menu mt-2 mb-4">
                                            <nav class="controls">
                                                {
                                                    galleryCat.map((item) => {
                                                        return <button key={item?.id} onClick={() => handleCatButton(item?.id, item?.name)} type="button" className={`control outline ${item?.active ? 'active-cat' : ''}`} data-filter="all">{item?.name}</button>
                                                    })
                                                }
                                            </nav>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="row portfolio-item">
                                            {
                                                slice.map((item) => (
                                                    <div class="mix blockA card col-lg-4 mt-3 mb-3 cursor-pointer" onClick={() => handleSlectedImage(item.media_url)}>
                                                        <div class="card-image">
                                                            <span href="" data-fancybox="gallery" data-caption="Caption Images 1">
                                                                <img src={item?.media_url} alt="" />
                                                            </span>
                                                        </div>
                                                        <div class="overlayhover">
                                                            <span class="zoom-icon"><img src="images/zoom-icon.svg" alt="" /></span>
                                                            <h6>{truncateData(item.location_name, 20)}</h6>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            noOfElement <= galleryFilterDataData.length && (
                                                <div class="row">
                                                    <button class="load-more-list" onClick={handleLoadmore}>LOAD MORE</button>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            )
                                :
                                <div style={{ textAlign: "center" }}>
                                    {"No images yet"}
                                </div>
                    }

                </div>
            </section>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={galleryFilterDataData}
                selectedImage={selectedImage}
            />

        </div>
    )
}

export default Gallery


function MyVerticallyCenteredModal(props) {
    let newImgArr = props.data.map((item) => item.media_url).filter((item) => item !== props.selectedImage);
    newImgArr.unshift(props.selectedImage);
    console.log("newImgArr", newImgArr)


    let newImgArr1 = props.data
        .map((item) => ({ original: item.media_url, name: item.location_name }))
        .filter((item) => item.original !== props.selectedImage);

    newImgArr1.unshift({ original: props.selectedImage, name: props.selectedImageName });


    // let imgs = newImgArr.map(item => ({ "original": item }));

    // console.log("imgs11111111111111", imgs);
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='ImageGallery'
        // dialogClassName="modal-90w"
        >

            <Modal.Body>
                <ImageGallery items={newImgArr1} />
            </Modal.Body>
        </Modal>
    );
}
