import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BookingDetailsSkeleton = () => {
    return (
        <div className="col-lg-5 col-md-7">
            <div className="proceedto-pay-wrapper">
                <div className="entry-pass-detail-items">
                    <div className="item-wrap">
                        <label>Entry Pass</label>
                        <h3><Skeleton width={150} height={50}/></h3>
                    </div>
                    <div className="item-wrap">
                        <label>Select Guests</label>
                        <h4><Skeleton width={100} height={50}/></h4>
                    </div>
                    <a href="/booking?type=edit"><Skeleton width={50} height={50}/></a>
                </div>
                <div className="solid-devider"></div>
                <div className="entry-pass-detail-items">
                    <div className="item-wrap">
                        <label>Date</label>
                        <h3><Skeleton width={150} height={50}/></h3>
                    </div>
                </div>
                <div className="solid-devider"></div>
                <div className="entry-pass-detail-items">
                    <div className="item-wrap">
                        <label>Address</label>
                        <h3><Skeleton width={200} height={50}/></h3>
                    </div>
                </div>
                <div className="dashed-devider"></div>

                {/* Add-ons Section */}
                <Skeleton count={3} />

                <ul className="price-brackdown">
                    <li>Entry fee <span className="price"><Skeleton width={50} height={50}/></span></li>
                    <li>Show Price <span className="price"><Skeleton width={50} height={50}/></span></li>
                    <li>GST (18%) <span className="price"><Skeleton width={50} height={50}/></span></li>
                    <li>Total <span className="price"><Skeleton width={50} height={50}/></span></li>
                </ul>
                <Skeleton  count={1} height={50}/>
            </div>
        </div>
    );
};

export default BookingDetailsSkeleton;
