import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React from 'react'

const UserDetailsModels = (props) => {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = yup.object().shape({
        userEmail: yup.string().email().required("Email is required"),
        userName: yup.string().required("name is required"),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
            .test('len', 'Phone number must be exactly 10 digits', val => val && val.replace(/[^\d]/g, '').length === 10)
            .required('Phone number is required'),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
        props.handleuserdetails(data);
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Enter details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='give-details' onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                        <div className="col-md-6">
                            <input type="text" placeholder='Your Name' {...register("userName")} />
                            <span className="form-error-msg">{errors?.userName?.message}</span>
                        </div>
                        <div className="col-md-6">
                            <input type="email" placeholder='Your Email' {...register("userEmail")} />
                            <span className="form-error-msg">{errors?.userEmail?.message}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <input type="text" placeholder='Phone Number' {...register("phone")} />
                            <span className="form-error-msg">{errors?.phone?.message}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button className='submit'>Submit</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UserDetailsModels