import React from 'react'
import { Link } from 'react-router-dom'
import { socialLinks } from '../common/socialLinks'
import VideoContainer from '../common/aboutYoutubeVideo'

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-4">
                        <div className="footer-about">
                            <Link to="#" className="footer-logo"><img src="/images/mahabharat-logo.svg" alt="" /></Link>
                            {/* <div className="about-contant">
                                <div className="video-container">
                                    <iframe width="1835" height="797" src="https://www.youtube.com/embed/pGDOSbLX1Xc" title="Jyotisar Before After" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div> */}


                            <VideoContainer
                                thumbnailSrc="https://img.youtube.com/vi/pGDOSbLX1Xc/hqdefault.jpg"
                                videoSrc="https://www.youtube.com/embed/pGDOSbLX1Xc"
                                videoTitle="Sample Video" // A descriptive title for the iframe
                            />
                            {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type..</p> */}
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="Useful-Links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><Link to="/booking">Booking</Link></li>
                                <li><Link to="/gallery">Explore museum</Link></li>
                                <li><Link to="/blogs">Blogs</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                                <li><Link to="/feedback">Give Feedback</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="address">
                            <h3>Address</h3>
                            <address>
                                Mahabharat Museum, Jyotisar. <br />Kurukshetr. (Haryana)
                            </address>
                            <br />
                            <h4>Follow Us</h4>
                            <ul>
                                <li><Link to={"javascript:void(0)"} ><img src="/images/facebook.svg" alt="" /></Link></li>
                                <li><Link to={"javascript:void(0)"} ><img src="/images/youtube.svg" alt="" /></Link></li>
                                <li><Link to={"javascript:void(0)"} ><img src="/images/instagram.svg" alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="copyright-text">
                        All rights reserved <Link to="#">Mahabharat Museum.</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer