import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"
import { apiAxios } from '../../api/axios';
import { truncateData } from '../common/truncateData';

import { useQuery } from '@tanstack/react-query'
import { getMuseumGallery } from '../../services/homeServices';

const MuseumGallery = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [galleryData, setGalleryData] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["gallery"],
        queryFn: getMuseumGallery,
    });

    const handleSlectedImage = (image) => {
        setModalShow(true);
        setSelectedImage(image);
    }

    return (
        <div>
            {
                data && (
                    <section className="gallery-section">
                        <div className="section-heading">
                            <h2>Museum Galleries</h2>
                        </div>
                        <div className="container">
                            <div className="row">
                                {

                                    data.slice(0, 8).map((item) => (
                                        <div className="card col-lg-3 mt-3 mb-3" onClick={() => handleSlectedImage(item.media_url)}>
                                            <div className="card-image">
                                                <img src={item.media_url} alt="" />
                                            </div>
                                            <div className="overlayhover">
                                                <span className="zoom-icon"><img src="images/zoom-icon.svg" alt="" /></span>
                                                <h6>{truncateData(item.location_name,20)}</h6>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="view-all-gallery">
                            <Link to="/gallery" className="custom-globle-button">SEE ALL IMAGES <span><img alt="" src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0MiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODQ4Mjg1IDhMNDAuNjE2MSA4TTQwLjYxNjEgOEwzMS41MjYzIDFNNDAuNjE2MSA4TDMxLjUyNjMgMTUiIHN0cm9rZT0iIzNBM0EzQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=' /></span></Link>
                        </div>
                    </section>
                )
            }
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                data={galleryData}
                selectedImage={selectedImage}
            />
        </div>
    )
}

export default MuseumGallery

function MyVerticallyCenteredModal(props) {
    let newImgArr = props.data.map((item) => item.media_url).filter((item) => item !== props.selectedImage);
    newImgArr.unshift(props.selectedImage);

    let imgs = newImgArr.map(item => ({ "original": item }));

    console.log("imgs", imgs);
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='ImageGallery'
        >

            <Modal.Body>
                <ImageGallery items={imgs} />
            </Modal.Body>
        </Modal>
    );
}