import React, { useMemo } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';

const RandomLocations = ({data}) => {

    const navigate = useNavigate();

    const exhibitsOptions = useMemo(()=>{
        if(data.length > 4){
            return {
                loop: true,
                autoplay: true,
                margin: 30,
                nav: true,
                dots: false,
                smartSpeed: 800,
                autoplayTimeout: 3000,
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 1
                    },
                    1000: {
                        items: 4
                    }
                }
            }
        }else{
            return {
                loop: false,
                autoplay: true,
                margin: 30,
                nav: true,
                dots: false,
                smartSpeed: 800,
                autoplayTimeout: 3000,
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 1
                    },
                    1000: {
                        items: 4
                    }
                }
            }
        }
    },[data])

    const handleClick = (id) => {
        navigate(`/exhibits-details?location_id=${id}`);
    }
    return (
        <div className={`container top-exibits ${data.length > 4 ?"":"no-slider"}`} >

            <OwlCarousel className='owl-theme' {...exhibitsOptions}>
                {
                    data.map((item) => (
                        <div className="exhibits-items" style={{maxWidth:"100%"}} onClick={() => handleClick(item.location_id)} key={item.location_id}>
                            <img src={item.web_icon} alt={item.location_name} />
                            <h3 className="english-landscape-painting">
                                {item.location_name}
                            </h3>
                        </div>
                    ))
                }
            </OwlCarousel>

        </div>
    )
}

export default RandomLocations