import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { videoArr } from '../../constants/youtubeUrls';

const MuseumTour = () => {

    const museumOptions = {
        loop: true,
        margin: 30,
        nav: true,
        dots: false,
        autoplay: true,
        nav: true,
        smartSpeed: 800,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1.6
            },
            1500: {
                items: 2.6
            }
        }
    }


    return (

        <>
            <div>
                <section className="Museum-Tour" style={{ backgroundImage: 'url(/images/gallery-img-4.jpg)' }}>
                    <div className="container-fluid p-0">
                        <div className="row m-0">
                            <div className="col-lg-4 p-0 Museum-left-section">
                                <div className="MuseumTour-left">
                                    <h2>Museum Tour</h2>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type..</p> */}
                                    <Link to="#" className="custom-globle-button">more about <span><img alt="" src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODkxODQ1IDhMMzUuODkxOCA4TTM1Ljg5MTggOEwyNy44OTE4IDFNMzUuODkxOCA4TDI3Ljg5MTggMTUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K' /></span></Link>
                                </div>
                            </div>
                            <div className="col-lg-6 p-0 Museum-right-section">

                                <div id="MuseumTour" className="with-custom-arrow bellow">
                                    <OwlCarousel className='owl-theme' {...museumOptions}>
                                        {
                                            videoArr.map((item, index) => (
                                                <Link to={item.url} target='_blank'>
                                                    <div className={`item`} key={item.id}>
                                                        <div className="MuseumTour-items">
                                                            <div className="image-row">
                                                                <img src={`https://img.youtube.com/vi/${item.id}/hqdefault.jpg`} alt="" />
                                                                <Link to={item.name} target='_blank' className="dview"><img src="images/360-icon.svg" alt="" /></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>

                                            ))
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>


    )
}

export default MuseumTour