import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const BookingListSkelton = ({ count }) => {
    const skeletons = [];

    for (let i = 0; i < count; i++) {
        skeletons.push(
            <div className="d-flex item" key={i}>
                <div className=''>
                    <Skeleton width={300} height={260} />

                </div>
                <div>
                    <div className="item-detail">
                        <div className="admin-detail">
                            <Skeleton width={100} />
                        </div>
                        <Skeleton width={300} />
                        <Skeleton count={5} />
                        <Skeleton width={100} />
                    </div>

                </div>
                {/* <div className="item-detail">
                    <div className="admin-detail">
                        <Skeleton width={100} />
                    </div>
                    <Skeleton width={300} />
                    <Skeleton count={5} />
                    <Skeleton width={100} />
                </div> */}
            </div>
        );
    }

    return <div className='container skelton-main'>{skeletons}</div>;
}

export default BookingListSkelton