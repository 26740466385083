export const getNextNDatesFormatted = () => {
    const n = 7;
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const currentDate = new Date();
    const targetDates = [];

    for (let i = 0; i < n; i++) {
        const targetDate = new Date(currentDate);
        targetDate.setDate(currentDate.getDate() + i);
        const dayOfWeek = daysOfWeek[targetDate.getDay()];
        const dayOfMonth = targetDate.getDate();
        const month = months[targetDate.getMonth()];
        const isActive = i === 0; // Today's date will have active: true

        // Format the date as "DD Month YYYY"
        const formattedDate = `${dayOfMonth.toString().padStart(2, '0')} ${month} ${targetDate.getFullYear()}`;

        targetDates.push({ id: i + 1, day: dayOfWeek, date: dayOfMonth, month: month, active: isActive, formattedDate: formattedDate });
    }

    return targetDates;


}

export const formatShowSelectedDate = (dateString) => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const dateParts = dateString.split(' ');
    const dayOfMonth = dateParts[0].padStart(2, '0');
    const monthName = dateParts[1].slice(0, 3); // Get the first three characters of the month
    const year = dateParts[2];
    const date = new Date(`${monthName} ${dayOfMonth}, ${year}`);
    const dayOfWeek = daysOfWeek[date.getDay()];

    return { day: dayOfWeek, date: dayOfMonth, month: monthName };
}