import React, { useEffect, useState } from 'react'
import "./style.css"
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom"
import { removeHashTag } from '../../components/common/removeHashTag';
import { apiAxios } from '../../api/axios';
import { socialLinks } from '../../components/common/socialLinks';
import { ColorRing } from 'react-loader-spinner';
const BlogDetails = () => {


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const blogId = queryParams.get('blog_id');
  const [blogDeatils, setBlogDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const getBlogDetails = async (id) => {
    setLoading(true)
    try {
      const res = await apiAxios.get(`blogs/blog_detail?blog_id=${id}`);
      console.log("resresresres", res)
      setBlogDetails(res?.data?.data[0])
    } catch (err) {

    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
    getBlogDetails(blogId)
  }, [blogId]);

  const handleRelatedBlog = (id) => {
    navigate(`/blogs/blog_listing?blog_id=${id}`)
  }

  console.log("blogDeatils", blogDeatils?.tags)



  return (
    <div>

      {
        loading ?
          <div className='d-flex justify-content-center align-items-center' style={{ height: "700px" }}>
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
          </div>
          :
          blogDeatils ? (
            <>
              <div class="inner-pages-header-banner" style={{ backgroundImage: 'url(/images/Museum-Tour-bg.png)' }}>
                <div class="container">
                  <h1>{blogDeatils.title}</h1>
                </div>
              </div>
              <div class="container mb-4">
                <div class="row">
                  <div class="col-md-8">
                    <div class="blog-details">
                      <img src={blogDeatils.image_url} alt="" />
                      <div class="admin-detail">

                        {
                          blogDeatils.tags && (
                            <span><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6.71671 13.5296C6.08949 14.1568 5.07256 14.1568 4.44533 13.5296L0.470418 9.55467C-0.156806 8.92745 -0.156806 7.91052 0.470418 7.28329L6.56783 1.18588C6.81884 0.934865 7.14595 0.774082 7.49803 0.728664L13.094 0.00677202C13.616 -0.0605629 14.0606 0.383999 13.9932 0.90597L13.2713 6.50197C13.2259 6.85405 13.0651 7.18116 12.8141 7.43217L6.71671 13.5296Z" fill="#858585" />
                              <path d="M11.0041 3.79897C11.0041 4.24249 10.6446 4.60203 10.2011 4.60203C9.75755 4.60203 9.39801 4.24249 9.39801 3.79897C9.39801 3.35546 9.75755 2.99592 10.2011 2.99592C10.6446 2.99592 11.0041 3.35546 11.0041 3.79897Z" fill="#858585" />
                              <rect x="8.61548" y="2.15381" width="3.23077" height="3.23077" rx="1.61538" fill="white" />
                            </svg>{removeHashTag(blogDeatils.tags).split(",").map((item) => item).join("  ")}</span>
                          )
                        }

                      </div>
                      <div dangerouslySetInnerHTML={{ __html: blogDeatils.description }} />
                    </div>
                    <div class="share-blog-post">
                      <ul>
                        <li><Link to={socialLinks.faceBook}><svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.00684 8.375L7.39566 5.84133H4.96453V4.19715C4.96453 3.50398 5.30414 2.82832 6.39297 2.82832H7.4982V0.671172C7.4982 0.671172 6.49523 0.5 5.53629 0.5C3.53418 0.5 2.22551 1.71352 2.22551 3.91031V5.84133H0V8.375H2.22551V14.5H4.96453V8.375H7.00684Z" fill="#7A7A7A" />
                        </svg> Facebook</Link></li>
                        <li><Link to={socialLinks.twitter}><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.4498 2H12.2907L8.26988 6.65913L13 13H9.29726L6.39505 9.15529L3.07823 13H1.23468L5.53455 8.01562L1 2H4.79661L7.41721 5.51418L10.4498 2ZM9.80313 11.8841H10.8227L4.2412 3.05769H3.14602L9.80313 11.8841Z" fill="#4F4F4F" />
                        </svg> Twitter</Link></li>
                        <li>
                          <Link to={socialLinks.linkedin}><svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_52_1255)">
                              <path d="M3.13375 14.4998H0.23125V5.15293H3.13375V14.4998ZM1.68094 3.87793C0.752813 3.87793 0 3.10918 0 2.18106C6.64312e-09 1.73525 0.177098 1.30769 0.492335 0.992457C0.807572 0.67722 1.23513 0.500122 1.68094 0.500122C2.12675 0.500122 2.5543 0.67722 2.86954 0.992457C3.18478 1.30769 3.36188 1.73525 3.36188 2.18106C3.36188 3.10918 2.60875 3.87793 1.68094 3.87793ZM13.9969 14.4998H11.1006V9.94981C11.1006 8.86543 11.0787 7.47481 9.59156 7.47481C8.0825 7.47481 7.85125 8.65293 7.85125 9.87168V14.4998H4.95188V5.15293H7.73562V6.42793H7.77625C8.16375 5.69356 9.11031 4.91856 10.5225 4.91856C13.46 4.91856 14 6.85293 14 9.36543V14.4998H13.9969Z" fill="#4F4F4F" />
                            </g>
                            <defs>
                              <clipPath id="clip0_52_1255">
                                <rect width="14" height="13.9997" fill="white" transform="translate(0 0.500122)" />
                              </clipPath>
                            </defs>
                          </svg>
                            Linkedin</Link></li>
                        <li>
                          <Link to={socialLinks.pinterest}><svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.72221 0.5C2.84428 0.5 0 2.41862 0 5.52377C0 7.49849 1.11078 8.62049 1.78398 8.62049C2.06168 8.62049 2.22156 7.84631 2.22156 7.62752C2.22156 7.36665 1.55678 6.81126 1.55678 5.72573C1.55678 3.4705 3.27344 1.87165 5.49501 1.87165C7.40521 1.87165 8.81894 2.95718 8.81894 4.95154C8.81894 6.441 8.22147 9.23479 6.28602 9.23479C5.58757 9.23479 4.99011 8.72989 4.99011 8.0062C4.99011 6.9459 5.73063 5.91927 5.73063 4.82532C5.73063 2.96841 3.09673 3.30501 3.09673 5.54901C3.09673 6.02025 3.15563 6.54198 3.36601 6.97115C2.97892 8.63732 2.1879 11.1198 2.1879 12.8364C2.1879 13.3666 2.26364 13.8883 2.31413 14.4184C2.4095 14.525 2.36181 14.5138 2.50768 14.4605C3.9214 12.5251 3.87091 12.1464 4.51045 9.61346C4.85546 10.2698 5.74746 10.6233 6.45432 10.6233C9.43323 10.6233 10.7712 7.72008 10.7712 5.10301C10.7712 2.31764 8.36453 0.5 5.72221 0.5Z" fill="#4F4F4F" />
                          </svg> Pinterest</Link></li>

                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4">

                    {
                      blogDeatils.tags && (
                        <div class="section-devider-cat">
                          <div class="tags">
                            <h4>Tags</h4>
                            <ul>
                              {
                                removeHashTag(blogDeatils.tags).split(",").map((item) => <li>{item}</li>)
                              }
                            </ul>
                          </div>
                        </div>
                      )
                    }


                    <div class="section-devider">
                      <div class="tags">
                        <h4>Related Blogs</h4>
                        <div className='collection-main'>
                          {
                            blogDeatils?.related_blogs.slice(0, 4).map((item) => (
                              <div className='collect-img' style={{ cursor: "pointer" }} onClick={() => handleRelatedBlog(item.blog_id)}>
                                <img src={item.image_url} alt='' />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) :
            <div style={{ textAlign: 'center' }}>
              <h3>No records found</h3>
            </div>
      }

    </div>
  )
}

export default BlogDetails