import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Masonry from 'react-masonry-css';
import { removeHashTag } from '../common/removeHashTag';

const BlogPagination = ({ data }) => {

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [showPagination, setShowPagination] = useState(false);

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    const masonryRef = useRef(null);
    const itemsPerPage = 6;

    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = data.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(data.length / itemsPerPage);


    useEffect(() => {
        setShowPagination(pageCount > 1); // Show pagination if there is more than one page
    }, [pageCount]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };

    function truncateText(text, maxLength = 100) {
        if (text.length > maxLength) {
            return text.substring(0, maxLength - 3) + '...'; // Subtract 3 for the ellipsis
        } else {
            return text;
        }
    }

    console.log("currentItems", currentItems)
    return (
        <>
            <div ref={masonryRef} className="bloggridview">

                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {currentItems.map((item, index) => (
                        <div className="my-masonry-grid_column" key={item.blog_id}>

                            <div class="item">
                                <div class="blog-img">
                                    <img src={item.image_url} alt="blog-img-6" />
                                    <h6>{item.created_date}</h6>
                                </div>
                                <div class="item-detail">
                                    <div class="admin-detail">
                                        {/* <span><svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.5 9.14286C3.4172 9.14286 0.821668 11.2823 0.0482202 14.1924C-0.210746 15.1667 0.611764 16 1.59653 16H11.4035C12.3882 16 13.2107 15.1667 12.9518 14.1924C12.1783 11.2823 9.5828 9.14286 6.5 9.14286Z" fill="#858585" />
                                            <path d="M9.62039 3.2C9.62039 4.96731 8.22334 6.4 6.5 6.4C4.77666 6.4 3.37961 4.96731 3.37961 3.2C3.37961 1.43269 4.77666 0 6.5 0C8.22334 0 9.62039 1.43269 9.62039 3.2Z" fill="#858585" />
                                        </svg> ADMIN</span> */}

                                        {
                                            item.tags && (
                                                <span><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.71671 13.5296C6.08949 14.1568 5.07256 14.1568 4.44533 13.5296L0.470418 9.55467C-0.156806 8.92745 -0.156806 7.91052 0.470418 7.28329L6.56783 1.18588C6.81884 0.934865 7.14595 0.774082 7.49803 0.728664L13.094 0.00677202C13.616 -0.0605629 14.0606 0.383999 13.9932 0.90597L13.2713 6.50197C13.2259 6.85405 13.0651 7.18116 12.8141 7.43217L6.71671 13.5296Z" fill="#858585" />
                                                    <path d="M11.0041 3.79897C11.0041 4.24249 10.6446 4.60203 10.2011 4.60203C9.75755 4.60203 9.39801 4.24249 9.39801 3.79897C9.39801 3.35546 9.75755 2.99592 10.2011 2.99592C10.6446 2.99592 11.0041 3.35546 11.0041 3.79897Z" fill="#858585" />
                                                    <rect x="8.61548" y="2.15381" width="3.23077" height="3.23077" rx="1.61538" fill="white" />
                                                </svg>{removeHashTag(item.tags).split(",").map((item)=>item).join("  ")}</span>
                                            )
                                        }
                                    </div>
                                    <h3><Link to={`blog_listing?blog_id=${item.blog_id}`}>{item.title}</Link></h3>
                                    <div dangerouslySetInnerHTML={{ __html: truncateText(item.description) }} />
                                    <Link to={`blog_listing?blog_id=${item.blog_id}`}>READ MORE +</Link>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Your Masonry items go here */}
                    {/* <div className="my-masonry-grid_column"><img src="/images/blog-img-1.jpg" alt="" /></div>
  <div className="my-masonry-grid_column"><img src="/images/blog-img-2.jpg" alt="" /></div>
  <div className="my-masonry-grid_column"><img src="/images/blog-img-3.jpg" alt="" /></div>

  <div className="my-masonry-grid_column"><img src="/images/blog-img-1.jpg" alt="" /></div>
  <div className="my-masonry-grid_column"><img src="/images/blog-img-2.jpg" alt="" /></div>
  <div className="my-masonry-grid_column"><img src="/images/blog-img-3.jpg" alt="" /></div> */}

                    {/* Add more items as needed */}
                </Masonry>


            </div>


            {showPagination && (
                <ReactPaginate
                    breakLabel=""
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="Previous"
                    forcePage={currentPage} // Ensure the correct page is active when user navigates back
                    renderOnZeroPageCount={null}
                    previousClassName={currentPage === 0 ? 'disabled' : ''} // Apply disabled class to previous button when on first page
                    previousLinkClassName={currentPage === 0 ? 'disabled-link' : ''} // Style disabled previous button
                    className='pagination'
                />
            )}
        </>
    )
}

export default BlogPagination