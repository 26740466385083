import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { URLS360 } from '../../constants/youtubeUrls';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import VideoContainer from '../common/aboutYoutubeVideo';

const BlockExhibits = ({ data, count }) => {
    console.log("countcount", count)
    const navigate = useNavigate();

    const handleClick = (id) => {
        navigate(`/exhibits-details?location_id=${id}`);
    }





    return (
        <>
            <div className='exhibit-section'>
                <div className='exhibit-block-b' style={{ textAlign: "center" }}>
                    <h2>{data.category}</h2>

                    <p style={{ width: "350px", margin: "0 auto", fontSize: "18px", fontWeight: "400", color: "#0A0A15" }}>{data.items[0]?.category_description}</p>
                </div>
                {
                    count > 4 ?
                        <CarouselComponent items={data.items} data={data} />
                        :

                        <div className={`container top-exibits block-${data.category.split(" ")[1]}`} style={{ marginTop: "39px" }}>
                            {
                                data.items.map((item, index) => {
                                    return (
                                        <div className="exhibits-items"  onClick={() => handleClick(item.location_id)} key={item.location_id}>
                                            <img src={item.web_icon} alt={item.location_name} />
                                            <h3 className="english-landscape-painting">
                                                {item?.location_name}
                                            </h3>
                                        </div>
                                    )
                                })
                            }

                        </div>
                }

            </div>
        </>
    )
}

export default BlockExhibits;


const CarouselComponent = (props) => {

    const { items , data} = props;

    const exhibitsOptions = {
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: false,
        smartSpeed: 800,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 4
            }
        }
    }

    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate(`/exhibits-details?location_id=${id}`);
    }

    return (
        <>
            <div className={`container top-exibits block-${data.category.split(" ")[1]}`} style={{ marginTop: "39px" }}>
                <OwlCarousel className='owl-theme' {...exhibitsOptions}>

                    {
                        items.map((item, index) => {
                            return (
                                <div className="exhibits-items" style={{ maxWidth: "100%" }} onClick={() => handleClick(item.location_id)} key={item.location_id}>
                                    <img src={item.web_icon} alt={item.location_name} />
                                    <h3 className="english-landscape-painting">
                                        {item?.location_name}
                                    </h3>
                                </div>
                            )
                        })
                    }

                </OwlCarousel>
            </div>
        </>
    )

};


