import React, { useEffect, useMemo, useState, Component } from 'react'
import "./style.css";
import { useLocation } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { ColorRing } from 'react-loader-spinner';
import { apiAxios } from '../../api/axios';

const TicketDetails = () => {
    
    let currentDate = moment();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('location_id');
    const [modalShow, setModalShow] = useState(false);
    const [showDetails, setShowDetails] = useState(null)
    const [loading, setLoading] = useState(false);
    const [showMember, setShowMember] = useState(false);

    const [selectedShowsWorkingHours, setSelectedShowsWorkingHours] = useState([])
    const [selectedShowsTiming, setSelectedShowsTiming] = useState({})
    const [adultMember, setAdultMember] = useState(0);
    const [childMember, setChildMember] = useState(0);
    const [error, setError] = useState("");
    const [showTimingPills, setShowTimingPills] = useState([]);
    const [membersFees, setMembersFees] = useState([]);
    const [selectedDay, setSelectedDay] = useState("");


    const daysName = useMemo(() => {
        let daysNames = [];
        for (let i = 0; i < 7; i++) {
            daysNames.push(currentDate.format('dddd')); // 'dddd' format gives the full name of the day
            currentDate.add(1, 'days'); // Move to the next day
        }
        return daysNames
    }, [currentDate])

    console.log("showTimingPills", showTimingPills)

    const getShowDetails = async () => {
        setLoading(true)
        try {
            const res = await apiAxios.get(`locations/getLocation_details?language_id=1&location_id=${id}`);
            console.log("res?.data", res);
            if (res?.data?.data.length === 0) {
                setShowDetails(null)

            } else {
                setShowDetails(res?.data?.data[0])
                setSelectedShowsWorkingHours(res?.data?.data[0]?.working_hours)

            }
        } catch (err) {
            console.log("err", err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getShowDetails()
    }, [id])

    console.log("selectedShowsWorkingHours",selectedShowsWorkingHours)

    const handleShowTiming = (workingHours) => {
        console.log("workingHours", workingHours);
        const days = daysName;
        const dayIndexMap = Object.fromEntries(days.map((day, index) => [day, index]));
        const sortedWorkingHours = workingHours.sort((a, b) => dayIndexMap[a.week_days] - dayIndexMap[b.week_days]);

        console.log(sortedWorkingHours);
        setSelectedShowsWorkingHours(sortedWorkingHours)

        setModalShow(true)
    }

    console.log("showDetails", showDetails)

    const ShowTimingSubmit = (timeObj) => {
        console.log("timeObj", timeObj)
        const day = timeObj.week_days;
        const data = timeObj.timings.filter((item) => item.active === true);
        console.log("data", data)
        setSelectedDay(day)
        setShowTimingPills(data)
        setModalShow(false);
        setError("")

    }
    const addMembers = () => {
        if (Object.keys(selectedShowsTiming).length === 0 || adultMember === 0 && childMember === 0) {
            setError("Please Select Show timing and  at least one member should be selected ")
        } else {
            setError("");
            let memberObj;

            if (adultMember > 0 && childMember > 0) {
                memberObj = [
                    { id: 1, category: "Adult", count: adultMember, prize: adultMember * +showDetails.ticket_fee_details[0].adult_fee },
                    { id: 1, category: "Children", count: childMember, prize: childMember * +showDetails.ticket_fee_details[0].child_fee },
                ]
            } else if (adultMember > 0 && childMember == 0) {
                memberObj = [
                    { id: 1, category: "Adult", count: adultMember, prize: adultMember * +showDetails.ticket_fee_details[0].adult_fee },
                ]
            } else {
                memberObj = [
                    { id: 1, category: "Adult", count: adultMember, prize: adultMember * +showDetails.ticket_fee_details[0].adult_fee },
                ]
            }
            setMembersFees(memberObj)

            console.log("memberObj", memberObj)



            console.log("run api");
        }

    };

    console.log("selectedShowsTiming", selectedShowsTiming)
    useEffect(() => {
        document.body.classList.add('bodygraycolor');
    
        // Cleanup function
        return () => {
          document.body.classList.remove('bodygraycolor');
        };
      }, []);
    return (
        <>
        <div>

            {
                !loading ?
                    showDetails !== null ?
                        <>
                            <div class="detail-page-banner">
                                <div class="container">
                                    <div class="banner-image">
                                        <img src={showDetails?.web_icon} alt={showDetails?.location_name} />
                                    </div>
                                </div>
                            </div>
                            <main>
                                <section class="mb-5">
                                    <div class="container">
                                        <div className="row details-page-data-panel">
                                            <div className="col-md-7">
                                                <div class="details-page-data-panel">
                                                    <h2>{showDetails?.location_name}</h2>                                                    
                                                    <div class="rating">
                                                        <span class="rating-point">4.5</span>
                                                        {<StarRatings
                                                            rating={4.5}
                                                            starDimension="20px"
                                                            starSpacing="5px"
                                                            starRatedColor="orange"
                                                        />}
                                                        <span class="review">16 reviews</span>
                                                    </div>
                                                    <div class="price">₹ 799</div>
                                                    <p>{showDetails.description}</p>                                            
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="ticket-seletion-details">
                                                    <div className="time" onClick={() => handleShowTiming(showDetails.working_hours)}>
                                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.75 3.57895C8.75 3.16473 8.41421 2.82895 8 2.82895C7.58579 2.82895 7.25 3.16473 7.25 3.57895H8.75ZM8.73684 8L8.73684 7.25L8.73684 8ZM12.4211 8.75C12.8353 8.75 13.1711 8.41421 13.1711 8C13.1711 7.58579 12.8353 7.25 12.4211 7.25L12.4211 8.75ZM8 14.25C4.54822 14.25 1.75 11.4518 1.75 8H0.25C0.25 12.2802 3.71979 15.75 8 15.75V14.25ZM14.25 8C14.25 11.4518 11.4518 14.25 8 14.25V15.75C12.2802 15.75 15.75 12.2802 15.75 8H14.25ZM8 1.75C11.4518 1.75 14.25 4.54822 14.25 8H15.75C15.75 3.71979 12.2802 0.25 8 0.25V1.75ZM8 0.25C3.71979 0.25 0.25 3.71979 0.25 8H1.75C1.75 4.54822 4.54822 1.75 8 1.75V0.25ZM8.75 7.26315V3.57895H7.25V7.26315H8.75ZM8.73684 8.75L12.4211 8.75L12.4211 7.25L8.73684 7.25L8.73684 8.75ZM7.25 7.26315C7.25 8.08431 7.91568 8.75 8.73684 8.75L8.73684 7.25C8.73677 7.25 8.73747 7.25 8.7387 7.25025C8.73988 7.25049 8.74109 7.25087 8.74219 7.25134C8.74431 7.25223 8.7455 7.2532 8.74615 7.25385C8.74679 7.2545 8.74776 7.25569 8.74866 7.2578C8.74913 7.25891 8.7495 7.26011 8.74974 7.2613C8.75 7.26253 8.75 7.26323 8.75 7.26315H7.25Z" fill="white" />
                                                        </svg> Select Time
                                                        </div>

                                                        <div className='selected-days'>
                                                            <div className='day'>{selectedDay !== "" && selectedDay}</div>
                                                            {
                                                                showTimingPills.map((item) => {
                                                                    return <button>{`${item.starttime} to ${item.closetime}`}</button>
                                                                })
                                                            }
                                                        </div>

                                                        <div className="select-members-form">
                                                            <h4>Select Guests</h4>
                                                            <div className='select-members'>
                                                                <form>
                                                                    <input type="number" placeholder='Adults' min="0" onChange={(e) => setAdultMember(e.target.value)} />
                                                                    <input type="number" placeholder='Children' min="0" onChange={(e) => setChildMember(e.target.value)} />
                                                                    <button type='button' className='add' onClick={addMembers}>Add</button>
                                                                </form>
                                                            </div>
                                                            <div className='membersFees'>
                                                                {
                                                                    membersFees.length > 0 && (
                                                                        membersFees.map((item) => (
                                                                            <div key={item.category} className='member-item'>
                                                                                <div>{`${item.category} - ${item.count}`}</div>
                                                                                <div>₹{item.prize}</div>                                                                                
                                                                            </div>
                                                                        ))
                                                                    )
                                                                }
                                                            </div>    
                                                        </div>               

                                                        <button class="Book-Tickets" ><svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8.25 5.5V8.5M8.25 11.5V14.5M8.25 17.5V19M8.25 2.5V1M12.75 14.5H22.5M12.75 10H18M4.5 1H25.5C27.1569 1 28.5 2.34315 28.5 4V5.5C28.5 6.32843 27.7882 6.96891 27.0615 7.36671C26.131 7.8761 25.5 8.86436 25.5 10C25.5 11.1356 26.131 12.1239 27.0615 12.6333C27.7882 13.0311 28.5 13.6716 28.5 14.5V16C28.5 17.6569 27.1569 19 25.5 19L4.5 19C2.84315 19 1.5 17.6569 1.5 16V14.5C1.5 13.6716 2.21179 13.0311 2.93846 12.6333C3.86899 12.1239 4.5 11.1356 4.5 10C4.5 8.86436 3.86899 7.8761 2.93846 7.36671C2.21179 6.96891 1.5 6.32843 1.5 5.5V4C1.5 2.34315 2.84315 1 4.5 1Z" stroke="#C2C2C2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg> Book Tickets</button>
                                                        <p style={{ color: "red" }}>{error}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="details-page-gallery">
                                                    <h3>Photos</h3>
                                                    <ul class="g-list">
                                                        {
                                                            showDetails?.gallary_images.length > 0 ?
                                                                showDetails?.gallary_images.map((item) => (
                                                                    <li><img src={item?.media_url} alt="" /></li>
                                                                ))
                                                                :
                                                                <p className='d-flex justify-content-center align-items-center' style={{ height: "200px" }}>No photos yet</p>

                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="details-page-gallery">
                                                    <h3>Video</h3>
                                                    {
                                                        showDetails?.video_urls.length > 0 ? (
                                                            <ul class="g-list">
                                                                <li><img src="images/Exhibits-img-1.png" alt="" /> <i><img src="images/play-video-icon.svg" alt="" /></i></li>
                                                                <li><img src="images/Exhibits-img-2.png" alt="" /> <i><img src="images/play-video-icon.svg" alt="" /></i></li>
                                                                <li><img src="images/Exhibits-img-3.png" alt="" /> <i><img src="images/play-video-icon.svg" alt="" /></i></li>
                                                                <li><img src="images/gallery-img-3.jpg" alt="" /> <i><img src="images/play-video-icon.svg" alt="" /></i></li>
                                                            </ul>
                                                        )
                                                            :
                                                            <p className='d-flex justify-content-center align-items-center' style={{ height: "200px" }}>No videos yet</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container">
                                        <div class="three-d-view">
                                            <h2>360 tour</h2>
                                            <div class="three-d-iframe">
                                                <img src="images/360-image.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </main>

                            <MyVerticallyCenteredModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                selectedShowsWorkingHours={selectedShowsWorkingHours}
                                ShowTimingSubmit={ShowTimingSubmit}
                                setSelectedShowsTiming={setSelectedShowsTiming} />
                        </>
                        :
                        <>
                            <h4>No Record Found</h4>
                        </>

                    :
                    <div className='d-flex justify-content-center align-items-center' style={{ height: "700px" }}>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    </div>
            }

        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Give Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form className='give-details' action="">
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="text" placeholder='Your Name' />
                                </div>
                                <div className="col-md-6">
                                    <input type="email" placeholder='Your Email' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="text" placeholder='Phone Number' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <button className='submit'>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>

    )
}



export default TicketDetails

const MyVerticallyCenteredModal = React.memo((props) => {
    const [data, setData] = useState([]);

    let arr = props.selectedShowsWorkingHours;

    useEffect(() => {
        setData(props.selectedShowsWorkingHours)
    }, [props.selectedShowsWorkingHours])

    console.log("props", arr)
    console.log("propsqqq", data)
    const handleShowTiming = (targetDay, targetTime) => {
        const updatedWorkingHours = arr.map((day) => {
            const dayObj = { ...day }; // Create a new object for the day

            // Check if the day is the target day
            const isTargetDay = dayObj.week_days === targetDay;

            // Reset active state and timings if the day is not the target day
            if (!isTargetDay) {
                dayObj.active = false;
                dayObj.timings = dayObj.timings.map((timing) => ({
                    ...timing,
                    active: false,
                }));
            } else {
                // If the day is the target day, toggle active state and update timings
                const isDayActive = dayObj.active;
                dayObj.active = !isDayActive;

                if (!isDayActive) {
                    dayObj.timings = dayObj.timings.map((timing) => {
                        if (timing.starttime === targetTime) {
                            timing.active = !timing.active;
                        } else {
                            timing.active = timing.active || false;
                        }
                        return timing;
                    });
                } else {
                    dayObj.timings = dayObj.timings.map((timing) => ({
                        ...timing,
                        active: false, // Reset all timings to false if the day is already active
                    }));
                }
            }

            return dayObj;
        });
        setData(updatedWorkingHours);
    }

    const handleSubmit = () => {
        const res = data.find((item) => item.active);
        console.log("res111111111", res)
        props.setSelectedShowsTiming(res)
        props.ShowTimingSubmit(res)
    }

    const check = useMemo(() => {
        return data.some((item) => item.active === true)
    }, [data])

    console.log("check", check);

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Show Timing
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="timing-table">
                    <ul>
                        {
                            data.length > 0 && (
                                data.map((item) => (
                                    <li className={`${item.availability === "close" ? "disable" : ""}`}>
                                        <span className='day'>{item.week_days}</span>
                                        <div className="time-list">
                                        {
                                            item?.timings?.map((ele) => (
                                                
                                                    <div className={`time-item ${ele.active ? 'active' : ''}`} onClick={() => handleShowTiming(item.week_days, ele.starttime)}>
                                                        <span className={`time-stamp`} >{`${ele.starttime} to ${ele.closetime}`}</span>
                                                    </div>
                                            ))
                                        }
                                        </div>

                                    </li>
                                ))
                            )

                        }
                    </ul>
                    <Button className='select-time-btn' onClick={handleSubmit} disabled={!check ? true : false}>Submit</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
})



