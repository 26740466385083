import React, { useEffect, useRef, useState } from 'react'
import './style/style.css'
import { useNavigate } from 'react-router-dom';


const Homecrousel = ({ homeSilderImages }) => {
    const navigate = useNavigate();

    const handleOnClick = (id) => {
        navigate(`/exhibits-details?location_id=${id}`);
    }
    
    const [currentIndex, setCurrentIndex] = useState(1);
    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setCurrentIndex((prev) => {
                const index = (prev % 5) + 1;
                const sliders = document.querySelectorAll('input[name="slider"]');
                sliders.forEach((slider) => slider.checked = false);
                const targetSlider = document.getElementById('s' + index);
                if (targetSlider) {
                    targetSlider.checked = true;
                }
                return index;
            })
        }, 3000);
        return () => clearInterval(sliderInterval);
    }, []);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
          const newIndex = prevIndex === 1 ? 5 : prevIndex - 1;
          const sliders = document.querySelectorAll('input[name="slider"]');
          
          sliders.forEach((slider) => {
            slider.checked = false; // Uncheck all sliders
          });
    
          const targetSlider = document.getElementById('s' + newIndex);
          if (targetSlider) {
            targetSlider.checked = true; // Check the target slider
          }
    
          return newIndex;
        });
      };
    
      const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
          const newIndex = (prevIndex % 5) + 1;
          const sliders = document.querySelectorAll('input[name="slider"]');
          
          sliders.forEach((slider) => {
            slider.checked = false; // Uncheck all sliders
          });
    
          const targetSlider = document.getElementById('s' + newIndex);
          if (targetSlider) {
            targetSlider.checked = true; // Check the target slider
          }
    
          return newIndex;
        });
      };

    
    return (
        <>
            <section id="slider" className="swiper-cards">
                <div className="nav prew-arrow"><svg width="19" height="34" viewBox="0 0 19 34" onClick={handlePrevClick} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9434 1.56372L2.35406 16.9292L16.9434 32.2946" stroke="white" strokeOpacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </div>

                {/* Radio inputs for slides */}
                {homeSilderImages.slice(0,5).map((item, index) => (
                    <input type="radio" key={item.id} name="slider" id={`s${index + 1}`} checked={(index+1) === currentIndex} />
                ))}

                {
                    homeSilderImages.slice(0, 5).map((item, index) => (
                        <>
                            <label key={item.location_id} onClick={() => handleOnClick(item.id)} htmlFor={`s${index + 1}`} id={`slide${index + 1}`} className="swiper-slide" style={{ backgroundImage: `url(${item?.image_url})` }}>
                                <div className="captions">
                                    <h2>{item?.title}</h2>
                                </div>
                            </label>


                        </>
                    ))
                }
                <div className="nav next-arrow" style={{ color: "black" }} onClick={handleNextClick}><svg width="18" height="34" viewBox="0 0 18 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.88269 1.56372L16.472 16.9292L1.88269 32.2946" stroke="white" strokeOpacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </div>
            </section >


        </>
    )
}
export default Homecrousel