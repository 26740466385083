import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import React from 'react'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiAxios } from '../../api/axios';

const UserDetailsProccedModels = (props) => {
    const navigate = useNavigate();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const schema = yup.object().shape({
        userEmail: yup.string().email().required("Email is required"),
        userName: yup.string().required("name is required"),
        phone: yup.string().matches(phoneRegExp, 'Phone number is not valid')
            .test('len', 'Phone number must be exactly 10 digits', val => val && val.replace(/[^\d]/g, '').length === 10)
            .required('Phone number is required'),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = async (data) => {
        // props.handleuserdetails(data);

        const parsedDate = moment(props.selectedDate, "DD MMM YYYY");
        const formattedDate = parsedDate.format("YYYY-MM-DD");
        const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

        try {
            const formdata = new FormData();
            formdata.append("first_name", data.userName);
            formdata.append("email", data.userEmail);
            formdata.append("phone", data.phone);
            formdata.append("language_id", "1");
            const res = await apiAxios.post("registration/create_anonymous_user", formdata);
            props.setUserDetails(res.data);
            const addOns = props.memberBookShows.map(item => ({ location_id: item.location_id, ticket_fee_details: item.ticket_fee_details[0], web_icon: item.web_icon, location_name: item.location_name, description: item.description, timing: item.selectedtiming, child_count: item.ChildMemberBooking, adult_count: item.AdultMemberBooking, foreigner_count: item.ForeignerMemberBooking }))
            const objApi = {
                "booking_datetime": currentTime,
                "event_date": formattedDate,
                "entry_details": {
                    "senior_citizen": 0,
                    "adult": props.adultMember,
                    "child": props.childMember,
                    "foreigner": props.foreignerMember,
                },
                "add_ons": addOns,
                userDetails: res.data
            };

            const storageObj = {
                shows: props.shows,
                memberBookShows: props.memberBookShows,
                selectedDate: props.selectedDate,
                addedEntryPassMember: props.addedEntryPassMember,
                adultMember: props.adultMember,
                childMember: props.childMember,
                foreignerMember: props.foreignerMember,
                formatedDates: props.formatedDates,
                userDetails: res.data,
                entryPassdateFormat: props.entryPassdateFormat
            }
            const objString = JSON.stringify(storageObj);
            const objApiString = JSON.stringify(objApi);
            localStorage.setItem('bookingData', objString);
            localStorage.setItem('objApiData', objApiString);

            navigate(`/procced-to-pay?type=booking`);
        } catch (err) {
            console.log("err", err)
        }


    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Enter details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='give-details' onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                        <div className="col-md-6">
                            <input type="text" placeholder='Your Name' {...register("userName")} />
                            <span className="form-error-msg">{errors?.userName?.message}</span>
                        </div>
                        <div className="col-md-6">
                            <input type="email" placeholder='Your Email' {...register("userEmail")} />
                            <span className="form-error-msg">{errors?.userEmail?.message}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <input type="text" placeholder='Phone Number' {...register("phone")} />
                            <span className="form-error-msg">{errors?.phone?.message}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button className='submit'>Submit</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UserDetailsProccedModels