import React, { useEffect, useState } from 'react'
import Homecrousel from '../../components/Home/crousel'
import AboutMusuem from '../../components/Home/aboutMuseum'
import MuseumExhibits from '../../components/Home/museumExhibits'
import Shows from '../../components/Home/shows'
import MuseumTour from '../../components/Home/museumTour'
import MuseumGallery from "../../components/Home/museumGallery"
import VistorInfo from '../../components/Home/vistorInfo'
import LatestNews from '../../components/Home/latestNews'
import axios from "axios";
import { ColorRing } from 'react-loader-spinner'
import { apiAxios } from '../../api/axios'

import { useQuery } from '@tanstack/react-query'
import { getHomePageData } from '../../services/homeServices'

const Home = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
  }, [])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["home"],
    queryFn: ({ signal }) => getHomePageData(signal),
  })


  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: "700px" }}>
        <ColorRing
          visible={true}
          height="80"
          width="80"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
        />
      </div>
    )
  }

  if (data) {
    return (
      <>
        {
          data.imgCrousel && <Homecrousel homeSilderImages={data.imgCrousel} />
        }
        <AboutMusuem />

        {
          data.uniqueBlocksExhibits && <MuseumExhibits homeExhibits={data.uniqueBlocksExhibits} />
        }

        {
          data.shows && <Shows shows={data.shows} />
        }
        <MuseumTour />
        <MuseumGallery />
        <VistorInfo />

      </>
    )
  }

  if(isError){
    return <div style={{textAlign:"center"}}>{error.message}</div>
  }



}

export default Home