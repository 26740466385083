import React, { useEffect, useState } from 'react';
import './style.css';
import BlogPagination from '../../components/blog/blogPagination';
import 'react-loading-skeleton/dist/skeleton.css'
import BlogListSkeleton from './skelton/blogListSkelton';
import { MdError } from "react-icons/md";
import { apiAxios } from '../../api/axios';


const BlogsPage = () => {

  const [blogData, setBlogData] = useState([])
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [errorMsg, setErrorMsg] = useState(false)
  const [emailSubscribed, setEmailSubscribed] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };


  const fetchBlogsData = async () => {
    setLoading(true)
    try {
      let result = await apiAxios.get("blogs/blog_listing?language_id=1");
      setBlogData(result.data.data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // This makes the scrolling smooth
    });
    fetchBlogsData()
  }, [])



  const handleSubscription = async () => {
    const check = validateEmail(email);

    if (check) {
      setErrorMsg(false)
      const formdata = new FormData();
      formdata.append("email", email);
      try {
        const response = await apiAxios.post(
          "newsletter/add_newsletter",
          formdata,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setEmail("");
        setEmailSubscribed(true)
        console.log(response.data);
      } catch (err) {
        console.log("err", err)
      }

    } else {
      setEmail("");
      setErrorMsg(true)
    }
  }



  return (
    <>
      <div className="inner-pages-header-banner" style={{ backgroundImage: 'url(/images/Newsletter-page-bg.jpg)' }}>
        <div className="container">
          <h1>Newsletter</h1>
        </div>
      </div>

      <div className='container'>
        <div className="email-subs" style={{ backgroundImage: 'url(/images/Visitor-news.png)' }}>

          {
            emailSubscribed ?
              <div>
                <div className='subscribed'>
                  <img src="images/right-shape.png" alt='' />
                  <p>Thank you for joining us. We're excited to embark on this journey together!</p>
                </div>
              </div>
              :
              <div className="container">
                <h1>Subscribe to our Newsletter</h1>
                <div className='email-wrapper-main'>
                  <input type="text" placeholder='Enter email' class="enter-email-wrapper" value={email} onChange={(e) => setEmail(e.target.value)} />
                  <button className='subscribe-wrapper' onClick={handleSubscription}>SUBSCRIBE</button>

                </div>
                {
                  errorMsg && <p className='error-msg'><MdError />{"Please enter valid email"}</p>
                }

              </div>
          }



        </div>
      </div>

      {
        loading ? <BlogListSkeleton count={3} /> :
          blogData.length > 0 && <BlogPagination data={blogData} />
      }


    </>
  )
}

export default BlogsPage
