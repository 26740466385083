import React, { useEffect, useState } from 'react'
import './style.css'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { apiAxios } from '../../api/axios';
const ContactUs = () => {

    const [formSubmitted, setFormSubmitted] = useState(false)
    const schema = yup.object().shape({
        userEmail: yup.string().email().required("Email is required"),
        userName: yup.string().required("name is required"),
        subject: yup.string().required("Subject is required"),
        message: yup.string().required("Message is required"),
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // This makes the scrolling smooth
        });
    }, [])

    const onSubmitHandler = async (data) => {
        console.log({ data });
        try {
            const formData = new FormData();
            formData.append("userName", data.userName);
            formData.append("userEmail", data.userEmail);
            formData.append("subject", data.subject);
            formData.append("message", data.message);

            const response = await apiAxios.post(
                "contact_us/add_contact",
                formData
            );
            setFormSubmitted(true)
            console.log(response.data); // Assuming the response contains JSON data
        } catch (error) {
            console.error(error);
        }
        reset();
    };
    return (
        <div>
            <div className="inner-pages-header-banner" style={{ backgroundImage: 'url(/images/ContactUs-page-bg.jpg)' }}>
                <div className="container">
                    <h1>Contact Us</h1>
                </div>
            </div>

            <section className="mb-5">
                <div className="container">

                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="rath-img">
                                <img src="/images/contact-img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="feedback-form contact-us">
                                <h4>REGULAR EXHIBITION HOURS</h4>
                                <h5>Monday to Saturday / <strong>10am-5pm</strong> <br />Sunday / <strong>11am-5pm</strong></h5>
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-field">
                                                <input type="text" className="form-control" placeholder="Your Name" {...register("userName")} />
                                                <span className="form-error-msg">{errors?.userName?.message}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-field">
                                                <input type="email" className="form-control" placeholder="Your Email" {...register("userEmail")} />
                                                <span className="form-error-msg">{errors?.userEmail?.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" className="form-control" placeholder="Subject" {...register("subject")} />
                                        <span className="form-error-msg">{errors?.subject?.message}</span>
                                    </div>
                                    <div className="input-field">
                                        <textarea className="form-control" placeholder="Your Message" {...register("message")}></textarea>
                                        <span className="form-error-msg">{errors?.message?.message}</span>
                                    </div>
                                    <div className="btn-grp">
                                        <button type="submit" className="submit-btn">Send</button>
                                    </div>
                                </form>
                                {
                                    formSubmitted && (
                                        <div className="alert alert-success alert-dismissible fade show mt-3" role="alert">
                                            <strong>Form Submitted Successfully!</strong>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ContactUs