import React, { useEffect, useState } from 'react'
import './style/style.css'
import { Link } from 'react-router-dom'
import { apiAxios } from '../../api/axios';
import VideoContainer from '../common/aboutYoutubeVideo';
import { useQuery } from '@tanstack/react-query'
import { getHomePageAboutData } from '../../services/homeServices';


const AboutMusuem = () => {

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["about"],
        queryFn: ({signal})=>getHomePageAboutData(signal),
    })


    return (
        <div id="aboutsection">
            {
                data && (
                    <>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="about-contant">
                                        <h1>{data.heading}</h1>

                                        <VideoContainer
                                            thumbnailSrc="images/cover-img.jpg"
                                            videoSrc="https://www.youtube.com/embed/4aQA60AmxV4"
                                            videoTitle="Sample Video" // A descriptive title for the iframe
                                        />

                                        {/* <div className='video-container'>
                                            
                                        </div> */}



                                        <p>{data?.main_lines}</p>
                                        <Link to="#" className="custom-globle-button">more about <span><img alt='about-musuem' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0MiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODQ4Mjg1IDhMNDAuNjE2MSA4TTQwLjYxNjEgOEwzMS41MjYzIDFNNDAuNjE2MSA4TDMxLjUyNjMgMTUiIHN0cm9rZT0iIzNBM0EzQSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=' /></span></Link>
                                    </div>
                                </div>
                                <div className="col-lg-6 text-end">
                                    <div className="about-img">
                                        <img src="images/about-image.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="jyotisarlogo">
                            <img src="images/jyotisar-logo.png" alt="" />
                        </div>
                    </>
                )
            }

        </div>
    )
}

export default AboutMusuem